import {getApiPath} from "../lib/getApiPath";
import {QueryClient, useQuery} from "react-query";
import stringify from "fast-json-stable-stringify";
import {defaultLeague, queryCacheStaleTime} from "../lib/Config";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {

            staleTime: queryCacheStaleTime,
            cacheTime: queryCacheStaleTime,
            retry: (failureCount, error) => {
                const statusCode = error?.status || -1
                if (statusCode >= 400 && statusCode < 500) return false
                return failureCount < 2
            }
        },
    },
})

window.queryClient = queryClient;

export const apiTypePlaylist = "playlist"
export const apiTypeGame = "game"
export const apiTypeTeam = "team"
export const apiTypeLiveIngest = "live_ingest"
export const apiTypeLineup = "lineup"
export const apiTypeIndividualStats = "individual_stats"
export const apiTypeStatistic = "Statistic"

export function useApi (path, type=undefined, {
    query=undefined, // query object, e.g. {count: 5}
    league=undefined,
    options=undefined, // options passed to useQuery - see react-query docs
}) {
    // For return values - see https://react-query.tanstack.com/reference/useQuery
    type = type || ""
    return useQuery(
        [type, path, league || "", query ? stringify(query) : ""],
        async () => fetchAndParse(type, path, query, league),
        options
    )
}

export function useApiByObjectId (id, type, options=undefined) {
    // For return values - see https://react-query.tanstack.com/reference/useQuery
    return useQuery([type, id], async () => {
        const url = getApiPath(`/${type}/${id}`, undefined, defaultLeague)
        return await fetcher(url)
    }, options)
}

export async function apiFetch (path, {
    query=undefined, // query object, e.g. {count: 5}
    league=undefined,
    options={
        staleTime: 2000,
    }, // options passed to useQuery - see react-query docs
}) {
    const url = getApiPath(path, query, league)
    try {
        const data = await queryClient.fetchQuery(["api", url], async () => {
            return await fetcher(url)
        }, options)
        return [data, null]
    } catch (e) {
        return [null, e]
    }
}

// -----internals--------

async function fetchAndParse (type, path, query, league) {
    const url = getApiPath(path, query, league)
    const data = await fetcher(url)

    if (type === apiTypePlaylist) cachePlaylists(data)
    else if (type === apiTypeGame) cacheGames(data)
    else if (type === apiTypeTeam) cacheTeams(data)
    else if (type === apiTypeLiveIngest) cacheLiveIngests(data)

    return data
}

async function fetcher (url) {
    const res = await fetch(url, {mode: "cors", credentials: "include"})

    if (res.ok) return await res.json()

    const error = new Error()
    error.status = res.status
    const {message} = await res.json().catch(() => ({message: ""}))
    error.message = message
    console.error("Failed request:", url, error.status, error.message)
    throw error
}

function cacheTeams ({teams}) {
    if (!teams) return
    teams.forEach(t => queryClient.setQueryData([apiTypeTeam, t.id], t))
}

function cacheGames ({games}) {
    if (!games) return
    games.forEach(g => queryClient.setQueryData([apiTypeGame, g.id], g))
}

function cachePlaylists ({playlists}) {
    if (!playlists) return
    let gameIds = new Set()
    playlists.forEach(p => {
        queryClient.setQueryData([apiTypePlaylist, p.id], p)
        if (p.game && !gameIds.has(p.game.id)) {
            gameIds.add(p.game.id)
            queryClient.setQueryData([apiTypeGame, p.game.id], p.game)
        }
    })
}

function cacheLiveIngests ({live_ingests}) {
    if (!live_ingests) return
    let playlists = []
    live_ingests.forEach(l => {
        playlists.push(l.playlist)
        queryClient.setQueryData([apiTypeLiveIngest, l.id], l)
    })
    cachePlaylists(playlists)
}